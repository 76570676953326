.bg-step-icon{
    height: 23px;
    width: 23px;
    border-radius: 50%;
    padding: 2px;
    border: 1.3px solid #A8B9CD;
}

.bg-active .bg-step-icon{
color: white;
border-color: #2cd48e;
background-color: #2cd48e;
}

.bg-active p{
    color: black;
    font-weight: 600;
    }
.steps-div{
        cursor: pointer;
    }


.summary-modal-css select , .summary-modal-css input{
    height: unset !important;
    border: 1px solid #E5E8F5;
    border-radius: 6px;
    padding: 6px;
    color: dimgrey;
}

.summary-modal-css .list-group-item{
    padding-right: 10px !important;
}

.table-overflow-set .table-responsive-sm{
    overflow: auto;
    margin-bottom: 35px;
}

.table-overflow-set .table-responsive-sm::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #519DFC;
	border-radius: 10px;
	background-color: #F5F5F5;

}

.table-overflow-set .table-responsive-sm::-webkit-scrollbar
{
	background-color: #F5F5F5;
}

.table-overflow-set .table-responsive-sm::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #519DFC;
	background-color: #519DFC;
}


.content-div{
    padding-top: 40px;
}

/* @media (min-width: 576px) {
    .modal-dialog {
        max-width: 1000px;
        margin: 1.75rem auto;
    }
} */